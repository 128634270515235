<template>
  <Stack
    tag="footer"
    direction="col"
    gap="md"
    align="center"
    justify="center"
    class="w-full py-lg px-sm md:px-sm bg-charcoal-default"
    :breakpoints="{ direction: { md: 'row' }, justify: { md: 'start' } }"
  >
    <NuxtLink to="/" class="w-[130px]" aria-label="MKM">
      <span class="sr-only">MKM</span>
      <SvgLogo name="mkm-yellow" class-name="w-[130px]" />
    </NuxtLink>
    <Stack direction="col" gap="2xs" align="center" justify="center" :breakpoints="{ align: { md: 'start' } }">
      <div class="text-center md:text-left leading-xs">
        <NuxtLink v-for="(simpleFooterLink, index) in simpleFooterLinks" :key="index" :to="simpleFooterLink.url">
          <Text tag="span" weight="semi" size="xs" align="center" class="text-charcoal-lighter hover:text-white">
            {{ simpleFooterLink.title }}<span v-if="index < simpleFooterLinks.length - 1">&nbsp;|&nbsp;</span>
          </Text>
        </NuxtLink>
      </div>
      <div class="text-center md:text-left leading-xs">
        <Text tag="span" weight="semi" size="xs" align="center" class="text-charcoal-lighter">
          © {{ year }}
          Building Supplies Limited. | Company Registration no: 03100815 | VAT Registered: GB 721 4534 61
        </Text>
      </div>
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
const simpleFooterLinks = [
  {
    title: "Terms & Conditions",
    url: "/policies/terms-and-conditions",
  },
  {
    title: "Privacy Policy",
    url: "/policies/privacy-policy",
  },
  {
    title: "Cookie Policy",
    url: "/policies/cookie-list",
  },
  {
    title: "Modern Slavery Act",
    url: "/sustainability/modern-slavery",
  },
  {
    title: "Tax Strategy",
    url: "/about/tax-strategy",
  },
];

const year = new Date().getFullYear();
</script>
